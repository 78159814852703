'use strict';

export default {
	route: '/ui/logout',
	setup: async function() {
		await fetch('/api/session/logout');
		Grape.UsersPlugin.setSession(null);
		Grape.navigate('/');
	}
};
