
class GrapeUIPlugin
{
	constructor(Grape, options)
	{
		this.Grape = Grape;
		this.options = options;

		Grape.UsersPlugin = this;
	}

	async onInit()
	{
		this.Grape.currentSession = null;
		this.Grape.currentSessionObservable = ko.observable(null);

		let data = await Grape.fetches.getJSON('/api/session/info', {}, {cache: 'no-cache'});
		if (data.status == 'OK')
		{
			this.Grape.config.public_settings = data.public_settings || {};
			this.setSession(data.session, null);
		}

		this.add_page_roles();
	}

	add_page_roles(){
		Grape.router.add_event_listener('beforeRouteEnter', this.check_page_roles);
	}

	check_page_roles(to, from) {
		const current_roles = Grape?.currentSession?.roles ?? [];
		const plugin_options = to?.route?.plugin_options?.['ui-ps-login'] ?? {};
		const roles = plugin_options?.roles ?? [];

		let redirect_url;
		redirect_url = plugin_options?.redirect_url ?? {
			name: `/ui/login`,
			bindings: {
				rr: encodeURIComponent(to.hash)
			},
			parameters: {}
		};

		if (roles.length > 0)
		{
			const is_match = current_roles?.some(r => roles.includes(r)) ?? false;
			if (is_match)
			{
				return true;
			}
			else
			{
				if (!Grape.currentSession){
					return redirect_url;
				} else {
					Grape.alerts.alert({
						type:'warning',
						title:'Permission Denied',
						message:'You do not have the necessary permissions to perform this action.'
					});
					return false;
				}
			}
		}
		else
		{
			return true
		};
	}

	async setSession(session, trigger_hook='onSessionChange')
	{
		if (session == null)
		{
			Grape.currentSession = null;
			localStorage.removeItem('session_id');
			localStorage.removeItem('roles');
			localStorage.removeItem('username');
		}
		else
		{
			Grape.currentSession = JSON.parse(JSON.stringify(session));
			localStorage.setItem('session_id', session.session_id);
			if (session.roles)
				localStorage.setItem('roles', session.roles);
		}

		let loading_dialog = window.document.createElement('dialog');
		loading_dialog.innerHTML = `<i class="fas fa-spinner fa-spin-pulse" style="font-size:3rem;color:white"></i>`;
		loading_dialog.style.position = "fixed";
		loading_dialog.style.width = "100vw";
		loading_dialog.style["max-width"] = "100vw";
		loading_dialog.style.height = "100vh";
		loading_dialog.style["max-height"] = "100vh";
		loading_dialog.style.border = "none";
		loading_dialog.style.background = "rgba(0, 0, 0, 0.2)";
		loading_dialog.style.display = "flex";
		loading_dialog.style.justifyContent = "center";
		loading_dialog.style.alignItems = "center";
		window.document.body.append(loading_dialog);
		loading_dialog.showModal();
		await this.injectPublishedAssets();
		loading_dialog.close();
		loading_dialog.remove();

		this.Grape.currentSessionObservable(session);
		if (trigger_hook)
			await Grape.plugins.triggerPluginHooks(trigger_hook, [Grape.currentSession]);
	}

	async injectPublishedAssets()
	{
		let list;
		try {
			list = await Grape.fetches.getJSON('/api/server/asset-list');
		} catch (err) {
			console.warn('GET /api/server/asset-list failed', err);
			return;
		}

		if (!list || !list.length)
			return;

		const p = [];
		for (const i of list)
		{
			if (i.type == 'js')
			{
				p.push(new Promise((resolve, reject) => {
					const script = document.createElement('script');
					script.src = i.url;
					script.type = 'module';
					script.onload = resolve;
					script.onerror = reject;
					document.head.appendChild(script);
				}));
			}
			else if (i.type == 'css')
			{
				p.push(new Promise((resolve, reject) => {
					const e = document.createElement('link');
					e.rel = 'stylesheet';
					e.href = i.url;
					e.onload = resolve;
					e.onerror = reject;
					document.head.appendChild(e);
				}));
			}
		}
		return Promise.all(p);
	}
}

export default GrapeUIPlugin;
